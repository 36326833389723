import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';

interface Props {
  accessOrder: AccessOrderDto;
}

const AccessOrderConfirmationAlert: FC<Props> = ({ accessOrder: _ }) => {
  return (
    <Alert severity="success">
      <AlertTitle>Confirmation de votre commande</AlertTitle>

      <Typography variant="body2" sx={{ mb: 1 }}>
        Il vous sera possible de récupérer vos accréditations, les
        laissez-passer de stationnement ainsi que les billets de prévente,
        directement à nos bureaux de Québec, entre le 12 et le 14 février 2025,
        ou au stand d’accréditation pendant les heures de montage du Salon à
        compter du lundi 17 février 13 h.
      </Typography>
    </Alert>
  );
};

export default AccessOrderConfirmationAlert;
